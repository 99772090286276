import { FC, useState } from 'react';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import makeStyles from '@mui/styles/makeStyles';
import { TextField, TextFieldProps } from '@mui/material';
import { dateFormatter } from '../../../../utils/dateFormatter';

const useStyles = makeStyles(() => ({
  inputBlock: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    borderRadius: 4,
    padding: 5,
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  datePickerInput: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff !important',
    background: 'transparent',
    borderRadius: 10,
    border: 'none',
    // maxWidth: 'fit-content',
    margin: '0 auto',
    '&:focus': {
      outline: 'none',
    },
    '& button': {
      color: '#F4F2EF',
    },
    '& fieldset': {
      border: '0!important',
    },
  },
  sx: {
    display: 'flex',
    alignItems: 'center',
  },
}));
const formattedDate = dateFormatter(new Date());

export const DateElement: FC = () => {
  const [date, setDate] = useState<string | Date>(formattedDate);
  const styles = useStyles();

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <DesktopDatePicker
        disabled
        value={date}
        onChange={(newDate: string | Date | null ) => {
          !!newDate && setDate(newDate);
        }}
        inputFormat="MMM d, yyyy"
        InputProps={{ className: styles.inputBlock }}
        renderInput={(params: TextFieldProps) => (
          <TextField disabled classes={{ root: styles.datePickerInput }} {...params} />
        )}
      />
    </LocalizationProvider>
  );
};
